import React, { useState } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  Slide,
  List,
  ListItemText,
  Collapse,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  AccountTree,
  AccountTreeOutlined,
  Help,
  ViewList,
  ViewListOutlined,
} from '@material-ui/icons';

import { CustomDrawer } from '../../../../../components/CustomDrawer';
import { TreeView } from '../../../../../components/TreeView';
import { Creators } from '../../../../../store/actionCreators';
import { AppState } from '../../../../../store/reducers';
import { NavigationOptions } from '../../../../../store/reports/types';

import { deviceSizes } from '../../../../../styles/supportedDevices';
import { Aside, CustomListItem, Container, TooltipSpan } from './styles';

export interface Menu {
  label: string;
  path: string;
  subMenus?: Menu[];
  description?: string;
}

export const NavigationPanel: React.FC<RouteComponentProps> = ({
  match,
  history,
}) => {
  const { reports } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();
  const treeHealth = useSelector((state: AppState) => state.treeHealth);

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const sortSubMenus = (menus) => {
    menus.forEach(menu => {
      menu.subMenus.sort((a, b) => a.label.localeCompare(b.label));
    });
    return menus;
  };

  const menuLogicalReports = sortSubMenus([
    {
      label: 'Saúde e Indicadores',
      path: `${match.path}/saudeeindicadores`,
      subMenus: [
        {
          label: 'Indicadores por Ativo Lógico',
          path: `${match.path}/saudeeindicadores/porelementologico`,
          description:
            'Relatório composto por todos ativos lógicos do nível selecionado e seus respectivos indicadores dentro de um período escolhido',
        },
        {
          label: 'Indicadores de Ativo Lógico por Período',
          path: `${match.path}/saudeeindicadores/porperiodo`,
          description:
            'Relatório composto pelos indicadores de um ativo, como uma malha de controle, selecionado a partir da árvore de elementos da empresa, e sua variação durante um período escolhido ',
        },
        {
          label: 'Comparação de Saúdes',
          path: `${match.path}/saudeeindicadores/comparacaodesaude`,
          description:
            'Relatório composto pela saúde dos filhos de um elemento da árvore da empresa e sua variação no período escolhido',
        },
      ],
    },
  ]);

  const menuPhysicalReports = sortSubMenus([
    {
      label: 'Saúde e Indicadores',
      path: `${match.path}/saudeeindicadores`,
      subMenus: [
        {
          label: 'Disponibilidade Física',
          path: `${match.path}/saudeeindicadores/disponibilidadefisica`,
          description:
            'Relatório composto pela disponibilidade física dos ativos físicos, filhos de um elemento, selecionado na árvore da empresa, em um período escolhido',
        },
        {
          label: 'Indicadores por Ativo Físico',
          path: `${match.path}/saudeeindicadores/porelementofisico`,
          description:
            'Relatório composto por todos ativos físicos do nível selecionado e seus respectivos indicadores dentro de um período escolhido',
        },
        {
          label: 'Indicadores de Ativo Físico por Período',
          path: `${match.path}/saudeeindicadores/fisicosporperiodo`,
          description:
            'Relatório composto pelos indicadores de um ativo, como um instrumento, selecionado a partir da árvore de elementos da empresa, e sua variação durante um período escolhido ',
        },
        {
          label: 'Comparação de Saúdes',
          path: `${match.path}/saudeeindicadores/comparacaodesaude`,
          description:
            'Relatório composto pela saúde dos filhos de um elemento da árvore da empresa e sua variação no período escolhido',
        },
      ],
    },
  ]);

  const menuPhysicalAndLogicalReports = sortSubMenus([
    {
      label: 'Saúde e Indicadores',
      path: `${match.path}/saudeeindicadores`,
      subMenus: [
        {
          label: 'Disponibilidade Física',
          path: `${match.path}/saudeeindicadores/disponibilidadefisica`,
          description:
            'Relatório composto pela disponibilidade física dos ativos físicos, filhos de um elemento, selecionado na árvore da empresa, em um período escolhido',
        },
        {
          label: 'Indicadores por Ativo Físico',
          path: `${match.path}/saudeeindicadores/porelementofisico`,
          description:
            'Relatório composto por todos ativos físicos do nível selecionado e seus respectivos indicadores dentro de um período escolhido',
        },
        {
          label: 'Indicadores por Ativo Lógico',
          path: `${match.path}/saudeeindicadores/porelementologico`,
          description:
            'Relatório composto por todos ativos lógicos do nível selecionado e seus respectivos indicadores dentro de um período escolhido',
        },
        {
          label: 'Indicadores de Ativo Lógico por Período',
          path: `${match.path}/saudeeindicadores/porperiodo`,
          description:
            'Relatório composto pelos indicadores de um ativo lógico, como uma malha de controle, selecionado a partir da árvore de elementos da empresa, e sua variação durante um período escolhido ',
        },
        {
          label: 'Indicadores de Ativo Físico por Período',
          path: `${match.path}/saudeeindicadores/fisicosporperiodo`,
          description:
            'Relatório composto pelos indicadores de um ativo físico, como um instrumento, selecionado a partir da árvore de elementos da empresa, e sua variação durante um período escolhido ',
        },
        {
          label: 'Comparação de Saúdes',
          path: `${match.path}/saudeeindicadores/comparacaodesaude`,
          description:
            'Relatório composto pela saúde dos filhos de um elemento da árvore da empresa e sua variação no período escolhido',
        },
      ],
    },
  ]);

  const menuSimpleReports = sortSubMenus([
    {
      label: 'Saúde e Indicadores',
      path: `${match.path}/saudeeindicadores`,
      subMenus: [
        {
          label: 'Comparação de Saúdes',
          path: `${match.path}/saudeeindicadores/comparacaodesaude`,
          description:
            'Relatório composto pela saúde dos filhos de um elemento da árvore da empresa e sua variação no período escolhido',
        },
      ],
    },
  ]);

  const collectedAssetType = treeHealth.data?.collectedAssetType;

  let menus: Menu[];
  switch (collectedAssetType) {
    case 'logical':
      menus = menuLogicalReports[0].subMenus
      break;

    case 'physical':
      menus = menuPhysicalReports[0].subMenus
      break;

    case 'both':
      menus = menuPhysicalAndLogicalReports[0].subMenus
      break;

    default:
      menus = menuSimpleReports[0].subMenus
      break;
  }

  function handleChangePanel(panel: NavigationOptions) {
    dispatch(Creators.setNavigationOption(panel));
  }

  function onLinkClick(path: string) {
    dispatch(Creators.toggleDrawer());
    handleChangePanel('treeView');
    history.push(path);
  }

  // Adds or removes element from list if multi mode or substitutes item in single mode
  function handleTreeItemSelect(id: string) {
    if (reports.treeItemsSelectionMode === 'single')
      dispatch(Creators.setElementUuid(id));
    else if (reports.elementUuid.includes(id)) {
      const index = reports.elementUuid.indexOf(id);
      const newArray = [...reports.elementUuid];
      newArray.splice(index, 1);
      dispatch(Creators.setElementUuid(newArray));
    } else dispatch(Creators.setElementUuid([...reports.elementUuid, id]));
  }

  function buildTreeView() {
    return (
      <>
        <div className="title">
          <h1>Estrutura</h1>
          <div className="panelsIcons">
            <Tooltip
              title={<TooltipSpan>Visualizar relatórios</TooltipSpan>}
              enterDelay={1000}
            >
              <span>
                <IconButton
                  onClick={() => handleChangePanel('menus')}
                  disabled={reports.selectableTreeItems.length === 0}
                >
                  <ViewListOutlined fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={<TooltipSpan>Visualizar árvore da empresa</TooltipSpan>}
              enterDelay={1000}
            >
              <span>
                <IconButton
                  onClick={() => {}}
                  disabled={reports.selectableTreeItems.length === 0}
                  style={{ backgroundColor: '#cbccd1' }}
                >
                  <AccountTree fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
        <div>
          <TreeView
            selectedItems={
              Array.isArray(reports.elementUuid)
                ? reports.elementUuid
                : reports.elementUuid == ''
                ? []
                : [reports.elementUuid]
            }
            onItemSelect={handleTreeItemSelect}
            selectionMode={reports.treeItemsSelectionMode}
            selectableTreeItems={reports.selectableTreeItems}
            showLeaves={true}
            showZeroImportance={true}
          />
        </div>
      </>
    );
  }

  const menuList = (
    <>
      <div className="title">
        <h1>Relatórios</h1>
        <div className="iconsPanel">
          <Tooltip
            title={<TooltipSpan>Visualizar relatórios</TooltipSpan>}
            enterDelay={1000}
          >
            <span>
              <IconButton
                onClick={() => {}}
                disabled={reports.selectableTreeItems.length === 0}
                style={{ backgroundColor: '#cbccd1' }}
              >
                <ViewList fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={<TooltipSpan>Visualizar árvore da empresa</TooltipSpan>}
            enterDelay={1000}
          >
            <span>
              <IconButton
                onClick={() => handleChangePanel('treeView')}
                disabled={reports.selectableTreeItems.length === 0}
              >
                <AccountTreeOutlined fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <List component="div" disablePadding>
        {menus?.map((item) => (
          <CustomListItem
            isSelected={selectedItem === item.path}
            key={item.label}
            button
            className="nested"
            onClick={() => {
              setSelectedItem(item.path);
              onLinkClick(item.path);
            }}
          >
            <ListItemText primary={item.label} />
            <ListItemSecondaryAction>
              <Tooltip
                title={<TooltipSpan>{item.description!}</TooltipSpan>}
              >
                <Help className="help-icon" />
              </Tooltip>
            </ListItemSecondaryAction>
          </CustomListItem>
        ))}
      </List>
    </>
  );

  return (
    <Media
      queries={{
        maxTablet: `(max-width: ${deviceSizes.tablet}px)`,
      }}
    >
      {(matches) => (
        <>
          {!matches.maxTablet && (
            <Aside isOpen={reports.isNavigationPanelOpen}>
              <Slide in direction="right">
                <Container isOpen={reports.isNavigationPanelOpen}>
                  <div
                    className={`card ${
                      reports.navigationOption === 'menus' &&
                      reports.isNavigationPanelOpen
                        ? 'selected'
                        : ''
                    }`}
                  >
                    {menuList}
                  </div>
                  <div
                    className={`card ${
                      reports.navigationOption === 'treeView' &&
                      reports.isNavigationPanelOpen
                        ? 'selected'
                        : ''
                    }`}
                  >
                    {buildTreeView()}
                  </div>
                </Container>
              </Slide>
            </Aside>
          )}
          {matches.maxTablet && (
            <CustomDrawer>
              <Container isOpen={reports.isNavigationPanelOpen}>
                <div
                  className={`card ${
                    reports.navigationOption === 'menus' &&
                    reports.isNavigationPanelOpen
                      ? 'selected'
                      : ''
                  }`}
                >
                  {menuList}
                </div>
                <div
                  className={`card ${
                    reports.navigationOption === 'treeView' &&
                    reports.isNavigationPanelOpen
                      ? 'selected'
                      : ''
                  }`}
                >
                  {buildTreeView()}
                </div>
              </Container>
            </CustomDrawer>
          )}
        </>
      )}
    </Media>
  );
};
