import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Collapse,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { format, getQuarter, startOfWeek } from 'date-fns';
import shortid from 'shortid';

import errorIcon from '../../../../../../../../assets/imgs/error-icon.png';
import paretoOnIcon from '../../../../../../../../assets/new_icons/ic-switch-pareto-select.svg';
import paretoOffIcon from '../../../../../../../../assets/new_icons/ic-switch-pareto-unselect.svg';
import tableOnIcon from '../../../../../../../../assets/new_icons/ic-switch-table-select.svg';
import tableOffIcon from '../../../../../../../../assets/new_icons/ic-switch-table-unselect.svg';
import { Bars } from '../../../../../../../../components/charts/Bars';
import { Table } from '../../../../../../../../components/charts/Table';
import { PickerType } from '../../../../../../../../components/PeriodDateFilter';
import { ToggleChartButton } from '../../../../../../../../components/ToggleChartButton';
import { HealthAndIndicatorsPeriodComparison } from '../../../../../../../../models/Reports';
import { Creators } from '../../../../../../../../store/actionCreators';
import { AppState } from '../../../../../../../../store/reducers';
import { PeriodResolution } from '../../../../../../../../store/reports/types';
import { kChartGradientColors } from '../../../../../../../../utils/constants';
import {
  randomDate2Date,
  generateCsv,
  getMonthString,
} from '../../../../../../../../utils/methods';
import { Header } from '../../Header';

import { useHistory, useLocation } from 'react-router-dom';
import { Container, CustomAccordion, TableCell } from './styles';
import { useAuthStore } from '../../../../../../../../zustand/AuthStore';

const timeList = [
  ['1d', 'week', 'Semana'],
  ['1w', 'month', 'Mês'],
  ['1month', 'trimester', 'Trimestre'],
  ['1month', 'semester', 'Semestre'],
].map(([resolution, picker, label]) => {
  return {
    resolution: resolution as PeriodResolution,
    picker: picker as PickerType,
    label,
  };
});

export interface HealthPeriodComparisonProps {}

export const HealthPeriodComparison: React.FC<HealthPeriodComparisonProps> = () => {
  const { reports } = useSelector((state: AppState) => state);
  const {
    isLoading,
    error,
    data,
    timePeriod,
    oldestTimePeriod,
    elementUuid,
    pickerType,
  } = reports;
  const { healthPeriodComparison } = data;
  const dispatch = useDispatch();
  const location: any = useLocation();

  const [selectedChart, setSelectedChart] = useState<'table' | 'bars'>('table');

  const tree = useAuthStore((state) => state.user.tree);

  useEffect(() => {
    if(!location.state?.isRedirect){
      dispatch(Creators.setNavigationOption('treeView'));
    }
    dispatch(Creators.setTreeOptions('all'));
  }, [dispatch]);

  useEffect(() => {
    if ((Array.isArray(elementUuid)) && tree) {
      dispatch(Creators.setElementUuid(tree.uuid));
    }
  }, [tree, dispatch, elementUuid]);

  useEffect(() => {
    if (elementUuid) {
      dispatch(
        Creators.getReportsRequest(
          typeof timePeriod.startTime === 'string'
            ? {
                startTime: startOfWeek(new Date()).getTime(),
                endTime: Date.now(),
              }
            : timePeriod,
          'healthAndIndicatorsPeriodComparison',
          oldestTimePeriod
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reports.elementUuid]);

  function buildPeriodString() {
    return [oldestTimePeriod, timePeriod].map((period) => {
      const startTime = randomDate2Date(period.startTime);
      const endTime = randomDate2Date(period.endTime);

      switch (pickerType) {
        case 'week':
          return `${format(startTime, 'dd/MM/yy')} - ${format(
            endTime,
            'dd/MM/yy'
          )}`;
        case 'month':
          return `${getMonthString(startTime)}/${format(startTime, 'yy')}`;
        case 'trimester':
          return `${getQuarter(startTime)}.º Trimestre`;
        default:
          return `${getQuarter(startTime) <= 2 ? '1' : '2'}.º Semestre`;
      }
    });
  }

  function buildHealth(health: number) {
    return `${Math.fround(health * 100).toFixed(2)}%`;
  }

  function buildHeaders(isSecond: boolean) {
    if (!isSecond) return ['Indicadores', 'Saúde'];
    return [
      'Período',
      <>
        <TableCell>
          {buildPeriodString().map((period) => (
            <div key={shortid.generate()} className="cellValue">
              {period}
            </div>
          ))}
        </TableCell>
      </>,
    ];
  }

  function buildRows(item: HealthAndIndicatorsPeriodComparison) {
    if(item.treeElementComparisonChildren.length > 0){
    return item.treeElementComparisonChildren!;
  }
    else{
      return [
        {
          name: item.name,
          identifier: item.identifier,
          healthResult:{
            oldestResult: item.healthResult.oldestResult,
            latestResult: item.healthResult.latestResult
          }
        },
      ]
    }
  }

  function createTableRow(item: HealthAndIndicatorsPeriodComparison) {
    return [
      item.name,
      <TableCell>
        {Object.values(item.healthResult).map((health) => (
          <div key={shortid.generate()} className="cellValue">
            {buildHealth(health)}
          </div>
        ))}
      </TableCell>,
    ];
  }

  function createCsvRow(item: HealthAndIndicatorsPeriodComparison) {
    return [
      item.name,
      buildHealth(item.healthResult.oldestResult).replace('.', ','),
      buildHealth(item.healthResult.latestResult).replace('.', ','),
    ];
  }

  function handleDownloadCsv() {
    if (healthPeriodComparison) {
      const oldestTimeStamp =
        typeof oldestTimePeriod.startTime === 'string'
          ? oldestTimePeriod.startTime.slice(4)
          : `${format(
              new Date(oldestTimePeriod.startTime!),
              'dd/MM/yyyy'
            )}-${format(new Date(oldestTimePeriod.endTime!), 'dd/MM/yyyy')}`;

      const latestTimeStamp =
        typeof timePeriod.startTime === 'string'
          ? timePeriod.startTime.slice(4)
          : `${format(new Date(timePeriod.startTime!), 'dd/MM/yyyy')}-${format(
              new Date(timePeriod.endTime!),
              'dd/MM/yyyy'
            )}`;

      generateCsv(
        ['Indicadores', 'Saúde', ''],
        [
          ['Período', ...buildPeriodString()],
          ...buildRows(healthPeriodComparison).map((row) => createCsvRow(row)),
        ],
        `Relatorio_${healthPeriodComparison.name}_Comparacao_Saude_${oldestTimeStamp}_${latestTimeStamp}.csv`
      );
    }
  }

  function generateChartValues() {
    const chartDataSource =
      healthPeriodComparison.treeElementComparisonChildren &&
      healthPeriodComparison.treeElementComparisonChildren.length > 0
        ? healthPeriodComparison.treeElementComparisonChildren
        : [healthPeriodComparison];
  
    return chartDataSource.map((item) => [
      item.name,
      Number(buildHealth(item.healthResult.oldestResult).slice(0, -1)),
      Number(buildHealth(item.healthResult.latestResult).slice(0, -1)),
    ]);
  }

  function buildToggleChartButtons(iconSize: number) {
    return (
      <>
        <ToggleChartButton
          onIcon={tableOnIcon}
          offIcon={tableOffIcon}
          alt="Mostrar ou esconder tabela"
          isSelected={selectedChart === 'table'}
          onToggle={() => setSelectedChart('table')}
          iconSize={iconSize}
          height={48}
          name="Tabela"
        />
        <ToggleChartButton
          onIcon={paretoOnIcon}
          offIcon={paretoOffIcon}
          alt="Mostrar ou esconder gráfico de combinação"
          isSelected={selectedChart === 'bars'}
          onToggle={() => setSelectedChart('bars')}
          iconSize={iconSize}
          height={48}
          name="Barras"
        />
      </>
    );
  }

  function buildErrorAndLoader() {
    if (error)
      return (
        <div className="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      );
    return (
      <div className="loader" data-testid="loader">
        <CircularProgress size={50} />
      </div>
    );
  }
  if (!elementUuid && reports.navigationOption == "menus")
    return (
    <Container className="no-element">
        <h2>Selecione um relatório para iniciar</h2>
      </Container>
    );
  if (!elementUuid && reports.navigationOption == "treeView")
    return (
      <Container className="no-element">
        <h2>Selecione um nível na árvore</h2>
      </Container>
    );

  return (
    <Container data-testid="reportContainer">
      <Header
        title="Comparação de Saúdes"
        subtitle={healthPeriodComparison?.name}
        datePicker="doublePeriod"
        reportName="healthAndIndicatorsPeriodComparison"
        hideSearchBar
        timeList={timeList}
        buildToggleChartsButtons={buildToggleChartButtons}
        handleDownloadCsv={handleDownloadCsv}
        handleSearchBarChange={() => {}}
      />
      {error || isLoading || !healthPeriodComparison ? (
        buildErrorAndLoader()
      ) : (
        <div className="charts" data-testid="reportBody">
          <Collapse in={selectedChart === 'table'} timeout="auto" unmountOnExit>
            <div className="table">
              <Table
                headers={buildHeaders(false)}
                secondHeaders={buildHeaders(true)}
                rows={buildRows(healthPeriodComparison)}
                createRow={createTableRow}
              />
            </div>
          </Collapse>
          <Collapse in={selectedChart === 'bars'} timeout="auto" unmountOnExit>
            <div className="bars">
              <CustomAccordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{healthPeriodComparison.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="bars-container">
                    <Bars
                      bars={buildPeriodString()}
                      values={generateChartValues()}
                      colors={[
                        kChartGradientColors[0],
                        kChartGradientColors[kChartGradientColors.length - 1],
                      ]}
                    />
                  </div>
                </AccordionDetails>
              </CustomAccordion>
            </div>
          </Collapse>
        </div>
      )}
    </Container>
  );
};
