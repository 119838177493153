import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, Button, Typography, Box } from '@material-ui/core';
import {
  ArrowBack,
  ArrowForward,
  Lock,
  LockOpen,
  Notifications,
} from '@material-ui/icons';

import { ResponsiveHeaderParams } from '..';

import { HealthIndicator } from '../../../../../../../../components/charts/HealthIndicator';
import { AppState } from '../../../../../../../../store/reducers';

import { Container, DelayedFade } from './styles';
import { sunburstLevelsDecription } from '../../../../../../../../utils/sunburstLevelsDescription';

export const MobileHeader: React.FC<ResponsiveHeaderParams> = ({
  isLogical,
  trailStrings,
  graphViewState,
  handleBlockAsset,
  toggleAssetAlertsModal,
}) => {
  const assetData = useSelector((state: AppState) => state.assetData);

  const [isTrailShowing, setIsTrailShowing] = useState(false);

  function handleTrailToggle() {
    setIsTrailShowing(!isTrailShowing);
  }

  return (
    <Container isTrailShowing={isTrailShowing} data-testid="mobileHeader">
      <div className="info-container">
        <div className="arrow-container">
          <Link
            to={{
              pathname: '/admin/dashboard',
              state: graphViewState,
            }}
          >
            <IconButton color="primary">
              <ArrowBack fontSize="large" />
            </IconButton>
          </Link>
          <h2>Informações do ativo</h2>
        </div>
        <HealthIndicator
          size={20}
          value={assetData.data.health}
          fontSize={14}
          healthType={isLogical ? 'logical' : 'physical'}
          width={90}
          spaceBetween={5}
        />
      </div>
      <div className="title-trail-container">
        <div className="title-container p-absolute">
          <div>
            <DelayedFade
              in={!isTrailShowing}
              unmountOnExit
              mountOnEnter
              delay={300}
            >
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  className="toggle-trail-button forward"
                  onClick={handleTrailToggle}
                  data-testid="toggleTrailButton"
                >
                  <ArrowForward />
                </Button>
              </div>
            </DelayedFade>
            <DelayedFade
              in={!isTrailShowing}
              delay={400}
              unmountOnExit
              mountOnEnter
            >
              <Typography variant="h1" noWrap>
                {assetData.data.name}
              </Typography>
            </DelayedFade>
          </div>
          <DelayedFade
            in={!isTrailShowing}
            delay={500}
            unmountOnExit
            mountOnEnter
          >
            <div>
              <div className="actions-container">
                <Button
                  color="secondary"
                  variant="contained"
                  className="action-btn"
                  onClick={handleBlockAsset}
                  data-testid="blockedButton"
                >
                  {assetData.data.blocked ? (
                    <LockOpen data-testid="unblockedIcon" />
                  ) : (
                    <Lock data-testid="blockedIcon" />
                  )}
                </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    className="action-btn"
                    onClick={() => toggleAssetAlertsModal(true)}
                    data-testid="alertButton"
                  >
                    <Notifications />
                  </Button>
              </div>
            </div>
          </DelayedFade>
        </div>
        <div className="trail-container p-absolute">
          <div className="trail">
            {trailStrings.map((item, index, array) => (
              <React.Fragment key={item}>
                <DelayedFade
                  in={isTrailShowing}
                  delay={index * 100}
                  unmountOnExit
                  mountOnEnter
                >
                  <div>
                    <Button
                      color="secondary"
                      variant="contained"
                      disableRipple
                      className={
                        array.length - 1 === index
                          ? 'breadcrumb gradient'
                          : 'breadcrumb'
                      }
                      style={{height: 'fit-content', padding: '2px 7px'}}
                    >
                      <Box flexDirection='column' width='100%'>
                        <p
                          style={{
                            color: array.length - 1 === index ? '#f2f2f2' : '#595959',
                            fontSize: '0.8em',
                          }}
                        >
                          {sunburstLevelsDecription[index]}
                        </p>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          padding='0px 7px'
                        >
                          <Typography noWrap component="span" variant="button">
                            {item}
                          </Typography>
                          <ArrowForward />
                        </Box>
                      </Box>
                    </Button>
                  </div>
                </DelayedFade>
              </React.Fragment>
            ))}
          </div>
          <DelayedFade in={isTrailShowing}>
            <div>
              <Button
                color="secondary"
                variant="contained"
                className="toggle-trail-button backward"
                onClick={handleTrailToggle}
                data-testid="toggleTitleButton"
              >
                <ArrowBack />
              </Button>
            </div>
          </DelayedFade>
        </div>
      </div>
    </Container>
  );
};
