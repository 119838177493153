import React, { useState, useEffect, useMemo } from 'react';
import Media from 'react-media';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useRouteMatch } from 'react-router-dom';

import {
  Select,
  MenuItem,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
  Slider,
  Input,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import {
  CheckBoxOutlineBlank,
  CheckBox,
  IndeterminateCheckBox,
} from '@material-ui/icons';

import errorIcon from '../../../../../../../../../assets/imgs/error-icon.png';
import { CustomButton } from '../../../../../../../../../components/CustomButton';
import { SearchField } from '../../../../../../../../../components/SearchField';
import { IndicatorAlert } from '../../../../../../../../../models/AssetInfo';
import { Creators } from '../../../../../../../../../store/actionCreators';
import useRealmCheck from '../../../../../../../../../store/auth/useRealmCheck';
import { CLIENTS, ROLES } from '../../../../../../../../../utils/authTypes';
import { useAuthStore } from '../../../../../../../../../zustand/AuthStore';

import { supportedDevices } from '../../../../../../../../../styles/supportedDevices';
import { Container, SliderValueLabel } from './styles';

const formColumnsAssetData: [string][] = [
  ['description'],
  ['importance'],
  ['criticality'],
  ['calibrable'],
  ['limits'],
];

const formColumns: [string][] = [['model'], ['modelDescription'], ['weights']];

const indicators: [string][] = [['CO e PA'], ['OL'], ['TV'], ['PF'], ['SQ']];

const headingStyles = {
  h2: {
    fontSize: '1.5em',
    fontWeight: 500,
  },
  h3: { fontSize: '1.17em', fontWeight: 500 },
  h4: { fontSize: '1.125em' },
  h5: { fontSize: '0.875em' },
};

const addOnStyles = {
  fontSize: '0.7em',
  color: 'gray',
};

interface MatchParams {
  ativoPath: string;
}

interface AlertFormState {
  importance?: number;
  criticality?: number;
  description?: string;
  calibrable?: boolean;
  model?: string;
  modelDescription?: string;
  tagsIndicator?: TagsIndicators;
  weights?: number[];
}

interface TagsIndicators {
  [name: string]: { [kpi: string]: number[] };
}

export interface EditAssetModalProps {
  /**
   * On cancel button click callback
   */
  handleCancel(): void;
  isLogicalAsset: boolean;
}

/**
 * Asset alerts editing modal
 */
export const EditAssetModal: React.FC<EditAssetModalProps> = ({
  handleCancel,
  isLogicalAsset,
}) => {
  const { assetData } = useSelector((state) => state);

  const keycloak = useAuthStore((state) => state.keycloak);

  const {user} = useAuthStore((state) => state);

  const dispatch = useDispatch();

  const match = useRouteMatch<MatchParams>();

  const [formState, setFormState] = useState<AlertFormState>({});
  const [dispatchCompleted, setDispatchCompleted] = useState(false);

  useEffect(() => {
    function dispatchPromise() {
      return new Promise<void>((resolve) => {
        if (!isLogicalAsset) {
          dispatch(Creators.getTagsRequest({ assetUuid: assetData.data.uuid }));
        }

        resolve();
      });
    }
    dispatchPromise().then(() => setDispatchCompleted(true));
  }, [dispatch, assetData.data.uuid]);

  useEffect(() => {
    if (
      assetData.data &&
      !Object.keys(formState).length &&
      (isLogicalAsset || dispatchCompleted)
    ) {
      let newFormState: AlertFormState = {};

      const limitsArray = assetData.data?.limits;
      const tagLimits = Object.keys(limitsArray).reduce((acc, curr) => {
        const tag = curr.split('_')[1]; // [0]: kpi (OL, TV, etc.), [1]: tag (SVALUE, SON, etc.)
        const kpi = curr.split('_')[0];

        // Ensure the tag exists in the accumulator
        if (!acc[tag]) {
          acc[tag] = {};
        }

        // Assign the kpi array to the tag
        acc[tag] = { ...acc[tag], [kpi]: limitsArray[curr] };
        return acc;
      }, {} as TagsIndicators);

      newFormState = {
        ...newFormState,
        importance: assetData.data?.importance,
        criticality: assetData.data?.criticality,
        description: assetData.data?.description,
        model: assetData.data?.model,
        modelDescription: assetData.data?.modelDescription,
        calibrable: assetData.data?.calibrable,
        tagsIndicator: tagLimits,
        weights: assetData.data?.weights,
      };

      setFormState(newFormState);
    }
  }, [assetData.data, formState, isLogicalAsset, dispatchCompleted]);

  function handleConfirm() {
    let weightsCopy: number[] = [];
    let weightTotal = 0.0;
    if (formState['weights']) {
      for (let weight of formState['weights']) {
        weightsCopy.push(Number(weight));
        weightTotal += Number(weight);
      }
    }

    if (weightTotal > 1.0) {
      toastr.error(
        'Valor total do peso não permitido',
        'A soma dos valores dos pesos devem ser menores que 1.0'
      );

      return;
    }

    let limitsCopy = {};
    // Iterating over each tag
    Object.keys(formState.tagsIndicator).forEach((tag) => {
      // Iterating over each indicator within the tag
      Object.keys(formState.tagsIndicator[tag]).forEach((indicator) => {
        // Creating a key following the pattern "indicator_tag"
        const key = `${indicator}_${tag}`;
        // Assigning the array values to limitsCopy
        limitsCopy[key] = formState.tagsIndicator[tag][indicator];
      });
    });

    dispatch(
      Creators.updateAssetRequest({
        assetUUid: assetData.data.uuid,
        description: formState['description'],
        model: formState['model'],
        modelDescription: formState['modelDescription'],
        importance: Number(formState['importance']),
        criticality: Number(formState['criticality']),
        calibrable: formState['calibrable'],
        weights: weightsCopy,
        limits: limitsCopy,
      })
    );

    handleCancel();
  }

  function handleFormChange(key: string, value: any) {
    setFormState({
      ...formState,
      [key]: value,
    });
  }

  function handleLimitChanges(
    minOrMax: string,
    kpi: string,
    value: number,
    tag: string
  ) {
    const kpiCopy = [...formState.tagsIndicator[tag][kpi]];
    minOrMax === 'min' ? (kpiCopy[0] = value) : (kpiCopy[1] = value);
    const newIndicator = {
      ...formState.tagsIndicator,
      [tag]: { ...formState.tagsIndicator[tag], [kpi]: kpiCopy },
    };
    setFormState({
      ...formState,
      tagsIndicator: newIndicator,
    });
  }

  function handleWeightChanges(index: number, value: any) {
    setFormState({
      ...formState,
      weights: formState.weights.map((weight, indexWeight) => {
        return index === indexWeight ? value : weight;
      }),
    });
  }

  function onSliderBlur() {
    if (formState['importance'] < 0) handleFormChange('importance', 0);
    else if (formState['importance'] > 100) handleFormChange('importance', 100);
  }

  function buildSlider() {
    return (
      <div className="slider-container campo">
        <p style={{ ...headingStyles.h3, width: 146 }}>Importância</p>
        <Input
          className="numerical-input "
          data-testid="ImportanceInput"
          margin="dense"
          onChange={(event) =>
            handleFormChange('importance', Number(event.target.value))
          }
          value={Number(formState['importance'] ? formState['importance'] : 0)}
          onBlur={() => onSliderBlur()}
          inputProps={{
            type: 'number',
          }}
          style={{ width: '' }}
        />
      </div>
    );
  }

  function buildDropDown() {
    return (
      <div className="dropdown-container campo">
        <p style={{ ...headingStyles.h3, width: 146 }}>Criticidade</p>
        <Select
          className="dropdownSelectCriticality"
          value={Number(formState.criticality) || 0}
          onChange={(event) =>
            handleFormChange('criticality', Number(event.target.value))
          }
          displayEmpty
          inputProps={{ 'aria-label': 'Criticality Dropdown' }}
          autoWidth
        >
          <MenuItem value={0} disabled>
            <em>-</em>
          </MenuItem>

          {[...Array(5)].map((_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  const buildSwitch = useMemo(() => {
    return (
          <div className="campo">
            <p style={{ ...headingStyles.h3, width: 146 }}>Calibrável</p>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    formState['calibrable'] ? formState['calibrable'] : false
                  }
                  onChange={(event) =>
                    handleFormChange('calibrable', event.target.checked)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={`${formState['calibrable'] ? 'Sim' : 'Não'}`}
            />
          </div>
    );
  }, [formState['calibrable'], assetData.data]);

  function buildWeightsInput() {
    return (
      <>
        {formState.weights && formState.weights.length && (
          <div className="key-form" style={{ paddingTop: 15 }}>
            <p style={{ ...headingStyles.h3 }}>Peso dos indicadores</p>
            <div className="indicators">
              {formState.weights.map((value, index) => (
                <div
                  style={{ display: 'flex', gap: 15, padding: 8 }}
                  key={index}
                >
                  <label
                    style={{
                      ...headingStyles.h5,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {indicators[index]}{' '}
                  </label>
                  <Input
                    disabled={
                      !keycloak ||
                      (!keycloak.hasRealmRole(ROLES.DEV) &&
                        !keycloak.hasRealmRole(ROLES.ADM))
                    }
                    value={value}
                    className="numerical-input input-indicador "
                    margin="dense"
                    onChange={(event) =>
                      handleWeightChanges(index, Number(event.target.value))
                    }
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  function createLimitsInput() {
    return (
      <>
        {Object.keys(formState.tagsIndicator).length > 0 && (
          <div>
            <p
              className="sub-title"
              style={{ ...headingStyles.h3, marginBottom: 10 }}
            >
              Limites das tags
            </p>
            {Object.keys(formState.tagsIndicator).map((tagIndicator) => (
              <div className="limits" key={tagIndicator}>
                <ul style={{ marginLeft: 20, marginBottom: 5 }}>
                  <li>
                    <p style={headingStyles.h5}>{tagIndicator}</p>
                  </li>
                </ul>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    alignItems: 'flex-start',
                    marginLeft: 20,
                  }}
                >
                  {formState.tagsIndicator[tagIndicator]['TV'] && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                      }}
                    >
                      <p style={{ ...headingStyles.h5, width: 120 }}>
                        Variação temporal
                      </p>
                      <Input
                        value={
                          formState.tagsIndicator[tagIndicator]['TV'][0] || 0.0
                        }
                        margin="dense"
                        className="limit-input numerical-input "
                        onChange={(event) =>
                          handleLimitChanges(
                            'min',
                            'TV',
                            Number(event.target.value),
                            tagIndicator
                          )
                        }
                        inputProps={{
                          type: 'number',
                        }}
                        startAdornment={<span style={addOnStyles}>min</span>}
                      />
                      <Input
                        value={
                          formState.tagsIndicator[tagIndicator]['TV'][1] || 0.0
                        }
                        margin="dense"
                        className="limit-input numerical-input "
                        onChange={(event) =>
                          handleLimitChanges(
                            'max',
                            'TV',
                            Number(event.target.value),
                            tagIndicator
                          )
                        }
                        inputProps={{
                          type: 'number',
                        }}
                        startAdornment={<span style={addOnStyles}>máx</span>}
                      />
                    </div>
                  )}

                  {formState.tagsIndicator[tagIndicator]['OL'] && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                      }}
                    >
                      <p style={{ ...headingStyles.h5, width: 120 }}>
                        Fora dos limites
                      </p>
                      <Input
                        value={
                          formState.tagsIndicator[tagIndicator]['OL'][0] || 0.0
                        }
                        margin="dense"
                        className="limit-input numerical-input "
                        onChange={(event) =>
                          handleLimitChanges(
                            'min',
                            'OL',
                            Number(event.target.value),
                            tagIndicator
                          )
                        }
                        inputProps={{
                          type: 'number',
                        }}
                        startAdornment={<span style={addOnStyles}>min</span>}
                      />
                      <Input
                        value={
                          formState.tagsIndicator[tagIndicator]['OL'][1] || 0.0
                        }
                        margin="dense"
                        className="limit-input numerical-input "
                        onChange={(event) =>
                          handleLimitChanges(
                            'max',
                            'OL',
                            Number(event.target.value),
                            tagIndicator
                          )
                        }
                        inputProps={{
                          type: 'number',
                        }}
                        startAdornment={<span style={addOnStyles}>máx</span>}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }

  function buildFormInput(key: string) {
    switch (key) {
      case 'description':
        return (
          <>
            <p className="title" style={headingStyles.h2}>
              Dados do ativo
            </p>
            <Divider style={{ marginBottom: 30 }} />
            <div className="plain-container campo">
              <p style={{ ...headingStyles.h3, width: 146 }}>
                Descrição do ativo
              </p>
              <Input
                style={{ flexGrow: 1 }}
                value={formState.description}
                margin="dense"
                onChange={(event) =>
                  handleFormChange('description', event.target.value)
                }
                inputProps={{
                  type: 'text',
                }}
              />
            </div>
          </>
        );
      case 'modelDescription':
        return (
          <>
            <p className="title not-first-title" style={headingStyles.h2}>
              Dados do modelo do ativo
            </p>
            <Divider style={{ marginBottom: 30 }} />
            <div className="plain-container campo model">
              <p style={{ ...headingStyles.h3, width: 360 }}>Nome do modelo</p>
              <Input
                disabled
                style={{ flexGrow: 1 }}
                value={formState.model}
                className="input-texto"
                margin="dense"
                onChange={(event) =>
                  handleFormChange('model', event.target.value)
                }
                inputProps={{
                  type: 'text',
                }}
              />
            </div>
            <div
              className="plain-container campo model-description"
              style={{ paddingTop: 15 }}
            >
              <p style={{ ...headingStyles.h3, width: 360 }}>
                Descrição do modelo do ativo
              </p>
              <Input
                disabled={
                  !keycloak ||
                  (!keycloak.hasRealmRole(ROLES.DEV) &&
                    !keycloak.hasRealmRole(ROLES.ADM))
                }
                style={{ flexGrow: 1 }}
                value={formState.modelDescription}
                className="input-texto"
                margin="dense"
                onChange={(event) =>
                  handleFormChange('modelDescription', event.target.value)
                }
                inputProps={{
                  type: 'text',
                }}
              />
            </div>
          </>
        );
      case 'importance':
        return buildSlider();
      case 'criticality':
        return buildDropDown();
      case 'calibrable':
        return buildSwitch;
      case 'limits':
        if (formState.tagsIndicator) {
          return createLimitsInput();
        }
        break;
      case 'weights':
        if (formState.weights && formState.weights.length) {
          return buildWeightsInput();
        }
        break;
      default:
        return <></>;
    }
  }



  function buildErrorAndLoader() {
    return assetData.isError ? (
      <>
        <div className="error-container" data-testid="error">
          <img src={errorIcon} alt="Ícone de erro" />
          <h3>Ocorreu um erro! Recarregue a página para tentar novamente.</h3>
        </div>
      </>
    ) : (
      <CircularProgress size={50} data-testid="loader" />
    );
  }

  return (
    <Media query={supportedDevices.tablet}>
      {(isTablet) => (
        <Container
          data-testid="modalContainer"
          className={!dispatchCompleted ? 'loading' : ''}
        >
          {!dispatchCompleted || assetData.isError ? (
            buildErrorAndLoader()
          ) : (
            <>
              <div className="title-container"></div>
              <div className="indicators-form">
                <div className="form-column">
                  <div
                    style={{
                      paddingTop: 15,
                      paddingBottom: 0,
                    }}
                    className={`key-form ${formColumnsAssetData['description']}-area`}
                  >
                    {buildFormInput('description')}
                  </div>
                  {formColumnsAssetData.slice(1, 3).map(([key], index) => (
                    <div
                      className={`key-form ${key}-area`}
                      key={index}
                      style={{
                        paddingTop: 15,
                        paddingBottom: 0,
                      }}
                    >
                      {buildFormInput(key)}
                    </div>
                  ))}

                  {user?.company.toLowerCase() === CLIENTS.SAMARCO.toLowerCase() && 
                  <div
                    className={`key-form ${formColumnsAssetData['calibrable']}-area`}
                    style={{
                      paddingTop: 15,
                      paddingBottom: 0,
                    }}
                  >
                    {buildFormInput('calibrable')}
                  </div>}

                  <div
                    className={`key-form ${formColumnsAssetData['limits']}-area`}
                    style={{
                      paddingTop: 15,
                      paddingBottom: 0,
                    }}
                  >
                    {buildFormInput('limits')}
                  </div>
                  {formColumns.map(([key], index) => (
                    <div className={`key-form ${key}-area`} key={index}>
                      {!isTablet && (index === 1 || index === 2) && (
                        <p className="slider-label">{key}</p>
                      )}
                      {buildFormInput(key)}
                    </div>
                  ))}
                </div>
              </div>
              <div className="action-buttons">
                <CustomButton data-testid="cancelButton" onClick={handleCancel}>
                  cancelar
                </CustomButton>
                <CustomButton
                  data-testid="confirmButton"
                  onClick={handleConfirm}
                  gradient
                >
                  confirmar
                </CustomButton>
              </div>
            </>
          )}
        </Container>
      )}
    </Media>
  );
};
